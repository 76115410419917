import MatchDots from "../../components/ExcerciseTypes/LineTo/MatchDots";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  1: {
    unit: "Unit 4",
    id: "WB7-U4-P27-E4",
    audio: "",
    video: "",
    component: T6,
    questionImage: [],
    inputSize: 170,
    exerciseKey: "img/FriendsPlus/Page28/E1/Key/answerKey.png",
    titleQuestion: [
      {
        num: "1",
        title: " Write the adjectives and adverbs.",
        color: "rgb(245, 128, 36)",
        star: 1,
      },
    ],
    questions: [
      {
        title: `
          <div style="display: flex; font-weight: 500">
						<div style=" padding: 12px;border: 2px solid rgb(251, 187, 135); border-radius: 10px 0 0 10px">
							<div style="margin: 10px; font-weight: 400"><b>Adjective</b></div>
							<div style="margin: 10px">slow</div>
							<div style="margin: 10px; display: flex"><div style="width: 35px; text-align: left"><b>1</b> </div><span>#</span></div>
							<div style="margin: 10px; display: flex"><div style="width: 35px; text-align: left"><b>2</b> </div>happy</div>
							<div style="margin: 10px; display: flex"><div style="width: 35px; text-align: left"><b>3</b> </div>creative</div>
							<div style="margin: 10px; display: flex"><div style="width: 35px; text-align: left"><b>4</b> </div><span>#</span></div>
							<div style="margin: 10px; display: flex"><div style="width: 35px; text-align: left"><b>5</b> </div>easy</div>
							<div style="margin: 10px; display: flex"><div style="width: 35px; text-align: left"><b>6</b> </div><span>#</span></div>
							<div style="margin: 10px; display: flex"><div style="width: 35px; text-align: left"><b>7</b> </div><span>#</span></div>
							<div style="margin: 10px; display: flex"><div style="width: 35px; text-align: left"><b>8</b> </div>good</div>
							<div style="margin: 10px; display: flex"><div style="width: 35px; text-align: left"><b>9</b> </div>quiet</div>
							<div style="margin: 10px; display: flex"><div style="width: 35px; text-align: left"><b>10</b> </div><span>#</span></div>
						</div>
						<div style=" padding: 12px;border: 2px solid rgb(251, 187, 135); border-radius: 0 10px 10px 0">
							<div style="margin: 10px; font-weight: 400"><b>Adverb</b></div>
							<div style="margin: 10px; color: grey; font-weight: bold; text-align: center; border-bottom: 1px dotted; padding-bottom: -40px;">slowly</div>
							<div style="margin: 10px">carefully</div>
							<div style="margin: 10px">#</div>
							<div style="margin: 10px">#</div>
							<div style="margin: 10px">rudely</div>
							<div style="margin: 10px">#</div>
							<div style="margin: 10px">badly</div>
							<div style="margin: 10px">patiently</div>
							<div style="margin: 10px">#</div>
							<div style="margin: 10px">#</div>
							<div style="margin: 10px">fast</div>
						</div>
					</div>
        `,
        answer: [
          "careful",
          "rude",
          "bad",
          "patient",
          "fast",
          "happily",
          "creatively",
          "easily",
          "well",
          "quietly",
        ],
      },
    ],
  },
  2: {
    unit: "Unit 4",
    id: "WB7-U4-P26-E2",
    audio: "",
    video: "",
    component: T6,
    questionImage: [],
    hintBox: [
      {
        src: [
          "brave / bravely",
          "comfortable / comfortably ",
          "fast / fast",
          "<s>happy / happily</s>",
          "polite / politely ",
          "rude / rudely",
          "slow / slowly",
        ],
        borderColor: "rgb(245, 128, 36)",
        width: "100%",
      },
    ],
    inputSize: 175,
    exerciseKey: "img/FriendsPlus/Page28/E2/Key/answerKey.png",
    textAlign: "center",
    titleQuestion: [
      {
        num: "2",
        title: "Complete the sentences with one of the words from each pair.",
        color: "rgb(245, 128, 36)",
        star: 2,
      },
    ],
    questions: [
      {
        title: `
              <div>
                <hintbox id="0"></hintbox>
                <div> ‘That’s a fantastic photo of our family,’ my mum said<u style="text-decoration: dotted underline;">&emsp;<b>happily</b>&emsp;</u></div>
                <div><b>1</b> I didn’t take a good photo of that small monkey because it was climbing very # up the tree.</div>
                <div><b>2</b> If you are # to people, they are usually happy to be in a photo.</div>
                <div><b>3</b> ‘Stop! Don’t take my photo!’ my brother shouted # .</div>
                <div><b>4</b> That man took a video from the top of the dangerous mountain. He was very # .</div>
                <div><b>5</b> Most animals don’t walk #. You need to be quick when you take a picture of them.</div>
                <div><b>6</b> ‘Please take my photo quickly!’ I said. ‘This old chair isn’t very # !’</div>
              </div>
          `,
        answer: ["fast", "polite", "rudely", "brave", "slowly", "comfortable"],
      },
    ],
  },
  3: {
    unit: "Unit 3",
    id: "WB7-U3-P22-E1",
    audio: "Audios/1-04 TA 7 Friends Plus Workbook.mp3",
    video: "",
    component: MatchDots,
    exerciseKey: "img/FriendsPlus/Page28/E3/Key/answerKey.png",
    titleQuestion: [
      {
        num: "3",
        title:
          "<audioimage name='04'></audioimage>Listen to Millie talking about two photos and match 1–7 with a–g.",
        color: "rgb(245, 128, 36)",
        star: 2,
      },
    ],
    // titleQuestion: [{ num: '2', title: "Listen again and draw the route to Leo's school.", color: "#2E479B" }],
    question: {
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: 197,
              left: 275,
              width: 20,
              height: 20,
              border: "1px solid black",
              borderRadius: "50%",
            },
          }, //0
          {
            boxMatchStyle: {
              position: "absolute",
              top: 197,
              left: 390,
              width: 20,
              height: 20,
              border: "1px solid black",
              borderRadius: "50%",
            },
          }, //0
          {
            boxMatchStyle: {
              position: "absolute",
              top: 237,
              left: 275,
              width: 20,
              height: 20,
              border: "1px solid black",
              borderRadius: "50%",
            },
          }, //1
          {
            boxMatchStyle: {
              position: "absolute",
              top: 237,
              left: 390,
              width: 20,
              height: 20,
              border: "1px solid black",
              borderRadius: "50%",
            },
          }, //2
          {
            boxMatchStyle: {
              position: "absolute",
              top: 277,
              left: 275,
              width: 20,
              height: 20,
              border: "1px solid black",
              borderRadius: "50%",
            },
          }, //3
          {
            boxMatchStyle: {
              position: "absolute",
              top: 277,
              left: 390,
              width: 20,
              height: 20,
              border: "1px solid black",
              borderRadius: "50%",
            },
          }, //5
          {
            boxMatchStyle: {
              position: "absolute",
              top: 317,
              left: 275,
              width: 20,
              height: 20,
              border: "1px solid black",
              borderRadius: "50%",
            },
          }, //4
          {
            boxMatchStyle: {
              position: "absolute",
              top: 317,
              left: 390,
              width: 20,
              height: 20,
              border: "1px solid black",
              borderRadius: "50%",
            },
          }, //7
          {
            boxMatchStyle: {
              position: "absolute",
              top: 357,
              left: 275,
              width: 20,
              height: 20,
              border: "1px solid black",
              borderRadius: "50%",
            },
          }, //8
          {
            boxMatchStyle: {
              position: "absolute",
              top: 357,
              left: 390,
              width: 20,
              height: 20,
              border: "1px solid black",
              borderRadius: "50%",
            },
          }, //9
          {
            boxMatchStyle: {
              position: "absolute",
              top: 397,
              left: 275,
              width: 20,
              height: 20,
              border: "1px solid black",
              borderRadius: "50%",
            },
          }, //10
          {
            boxMatchStyle: {
              position: "absolute",
              top: 397,
              left: 390,
              width: 20,
              height: 20,
              border: "1px solid black",
              borderRadius: "50%",
            },
          }, //11
          {
            boxMatchStyle: {
              position: "absolute",
              top: 437,
              left: 275,
              width: 20,
              height: 20,
              border: "1px solid black",
              borderRadius: "50%",
            },
          }, //8
          {
            boxMatchStyle: {
              position: "absolute",
              top: 437,
              left: 390,
              width: 20,
              height: 20,
              border: "1px solid black",
              borderRadius: "50%",
            },
          }, //9
          {
            boxMatchStyle: {
              position: "absolute",
              top: 477,
              left: 275,
              width: 20,
              height: 20,
              border: "1px solid black",
              borderRadius: "50%",
            },
          }, //10
          {
            boxMatchStyle: {
              position: "absolute",
              top: 477,
              left: 390,
              width: 20,
              height: 20,
              border: "1px solid black",
              borderRadius: "50%",
            },
          }, //11
        ],
        answers: ["0-5", "2-9", "1-4", "13-6", "7-8", "10-3", "11-12"],
        initialValue: ["0-5"],
      },
      Layout: `
        <div style="text-align: center"><img style="width: 78%" src='img/FriendsPlus/Page28/E3/1.jpg'/></div>
        <div style="font-size: 26px; margin-top: 10px">
          <div style='display: flex;'>
            <div style='width: 300px;'><b>1</b> Millie is studying</div>
						<div><input id='0' type= 'boxMatch' /></div>
            <div><input id='1' type= 'boxMatch' /></div>
						<div style='margin-left: 120px;'><b>a.</b> near the river.</div>
          </div>

					<div style='display: flex;'>
            <div style='width: 300px;'><b>2</b> She always carries</div>
						<div><input id='2' type= 'boxMatch' /></div>
            <div><input id='3' type= 'boxMatch' /></div>
						<div style='margin-left: 120px;'><b>b.</b>  her brother.</div>
          </div>
					
					<div style='display: flex;'>
            <div style='width: 300px;'><b>3</b> She was walking</div>
						<div><input id='4' type= 'boxMatch' /></div>
            <div><input id='5' type= 'boxMatch' /></div>
						<div style='margin-left: 120px;'><b>c.</b> photography.</div>
          </div>
					
					<div style='display: flex;'>
            <div style='width: 300px;'><b>4</b> It was Anna’s</div>
						<div><input id='6' type= 'boxMatch' /></div>
            <div><input id='7' type= 'boxMatch' /></div>
						<div style='margin-left: 120px;'><b>d.</b> photos of sport.</div>
          </div>
					
					<div style='display: flex;'>
            <div style='width: 300px;'><b>5</b> Millie doesn’t like</div>
						<div><input id='8' type= 'boxMatch' /></div>
            <div><input id='9' type= 'boxMatch' /></div>
						<div style='margin-left: 120px;'><b>e.</b> her dad’s camera.</div>
          </div>
					
					<div style='display: flex;'>
            <div style='width: 300px;'><b>6</b> She was watching</div>
						<div><input id='10' type= 'boxMatch' /></div>
            <div><input id='11' type= 'boxMatch' /></div>
						<div style='margin-left: 120px; margin-top: 4px;'><b>f.</b> to school.</div>
          </div>
          <div style='display: flex;'>
            <div style='width: 300px;'><b>7</b>  She took two photos</div>
						<div><input id='12' type= 'boxMatch' /></div>
            <div><input id='13' type= 'boxMatch' /></div>
						<div style='margin-left: 120px; margin-top: 4px;'><b>g.</b> birthday.</div>
          </div>
        </div>
      `,
    },
  },

  4: {
    unit: "Unit 3",
    id: "WB7-U3-P24-E4",
    audio: "Audios/1-04 TA 7 Friends Plus Workbook.mp3",
    video: "",
    component: T6,
    questionImage: [],
    inputSize: 170,
    exerciseKey: "img/FriendsPlus/Page28/E4/Key/answerKey.png",

    stylesTextInput: { textAlign: "center" },
    titleQuestion: [
      {
        num: "4",
        title:
          "<audioimage name='04'></audioimage> Listen again. Complete the sentences with an adjective or adverb.",
        color: "rgb(245, 128, 36)",
        star: 2,
      },
    ],
    questions: [
      {
        title: `
              <div>
                <div> It’s one of my favourite school subjects, but it isn't <u style="text-decoration: dotted underline;color: grey">&emsp;<b>easy</b>&emsp;</u></div>
                <div><b>1.</b> But I’m really # with that camera and I always carry it in a small bag.</div>
                <div><b>2.</b> In this photo, she was jumping # into the air and she was smiling, too.</div>
                <div><b>3.</b> You always need to work # and choose the most interesting angle for any photo.</div>
                <div><b>4.</b> It isn’t # to take photos of sports like skateboarding.</div>
                <div><b>5.</b> If you wait # , you can take an exciting photo like this.  </div>
                <div><b>6.</b> I think it’s really # to show your photos to other people.</div>
              </div>
          `,
        answer: [
          "careful",
          "happily",
          "creatively",
          "easy",
          "patiently",
          "good",
        ],
      },
    ],
  },

  5: {
    unit: "Unit 3",
    id: "WB7-U3-P21-E5",
    audio: "",
    video: "",
    component: T6,
    questionImage: [],
    textareaStyle: { width: 700, resize: "none", borderBottom: "none" },
    hideBtnFooter: true,
    hintBox: [
      {
        src: [
          "cook",
          "cycle",
          "do homework",
          "get up ",
          "play the piano",
          "run",
          "sing",
          "speak ",
          "study",
          "swim",
          "wait",
          "walk",
        ],
        width: "100%",
        borderColor: "rgb(245, 128, 36)",
      },
    ],
    titleQuestion: [
      {
        num: "5",
        title:
          "How do you do different things? Write sentences using adverbs and because.Use the words and phrases to help you.",
        color: "rgb(245, 128, 36)",
        star: 3,
      },
    ],
    questions: [
      {
        title: `
          <div><hintbox id="0"></hintbox></div>
          <div><textarea width="100%" id="0" rows="11">I get up slowly in the morning because I'm tired. </textarea></div>
        `,
        answer: [],
      },
    ],
  },
};

export default json;
